import React from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";

import Development from "../../../assets/development.svg";
import Joy from "../../../assets/joyride.svg";
import Trust from "../../../assets/trust.svg";
import GetIntlArrayObject from "../../utils/GetIntlArrayObject";

import Philosophy from "./philosophy";

export default function Philosophies() {
  const intl = useIntl();
  const text = GetIntlArrayObject("philosophy.ph", intl.messages);

  /**
   *
   * @param index index matches placement in language file (sv.json/en.json)
   */
  const getSvg = (index: string) => {
    if (index === "1") {
      return <Trust />;
    }

    if (index === "2") {
      return <Joy />;
    }

    if (index === "3") {
      return <Development />;
    }

    return <></>;
  };

  return (
    <div className="d-content d-content--pad values">
      <div className="values__title">
        <h2 className="title is-size-1 is-size-2-mobile has-text-centered orange-color-text">
          <FormattedMessage id="philosophy.title" />
        </h2>
      </div>
      {text.map((t, index) => {
        if (index % 2 === 0) {
          return <Philosophy key={t.subtitle} svg={getSvg(t.index)} text={t.text} title={t.subtitle} />;
        }

        return <Philosophy key={t.subtitle} reverse svg={getSvg(t.index)} text={t.text} title={t.subtitle} />;
      })}
    </div>
  );
}
