import React from "react";
import Fade from "react-reveal/Fade";
import useMedia from "Src/hooks/useMedia";

interface Props {
  title: string;
  text: string;
  classNameSelector?: string;
  reverse?: boolean;
  svg: JSX.Element;
}

export default function Philosophy({ title, text, reverse, svg }: Props) {
  const desktopScreen = useMedia("(min-width: 1024px)");

  const boxes = (
    <Fade left={desktopScreen && reverse} right={desktopScreen && !reverse} up={!desktopScreen}>
      <div className="column is-4-widescreen is-12-mobile">
        <div className="value__boxes">
          <div className={`value__box value__box--1 ${reverse ? "value__box--right" : "value__box--left"}`} />
          <div className="value__box value__box--2">{svg}</div>
        </div>
      </div>
    </Fade>
  );

  return (
    <section>
      <div className="value">
        <div className={`columns is-multiline is-centered ${reverse ? "reverse-row-order" : ""}`}>
          {!reverse && boxes}
          <div className="column is-5-desktop is-12-mobile">
            <div className="level container value__text-container">
              <div className="level-item value__text-sub-container">
                <Fade cascade left={desktopScreen && reverse} right={desktopScreen && !reverse} up={!desktopScreen}>
                  <div>
                    <div className="value__title">
                      <h3 className="title is-size-2 is-size-3-mobile">{title}</h3>
                    </div>
                    <p className="ingress">{text}</p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          {reverse && boxes}
        </div>
      </div>
    </section>
  );
}
