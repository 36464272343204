import React from "react";

import Layout from "../components/layout";
import PhilosophiesComponent from "../components/philosophies/philosophies";

export default function Philosophies() {
  return (
    <Layout>
      <div className="philosophy-container mt-6 pt-6">
        <PhilosophiesComponent />
      </div>
    </Layout>
  );
}
